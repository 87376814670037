.style-switcher {
  position: fixed;
  right: 0;
  top: 77px;
  padding: 15px;
  width: 200px;
  z-index: 101;
  border-radius: 5px;
  transition: all 0.3s ease;
  transform: translateX(100%);
}

.style-switcher.open {
  transform: translateX(-15px);
}

.style-switcher .s-icon {
  position: absolute;
  height: 40px;
  width: 40px;
  text-align: center;
  font-size: 20px;
  color: var(--text-black-900);
  right: 100%;
  margin-right: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 50%;
}

.style-switcher .s-icon i {
  line-height: 40px;
}

.style-switcher .s-icon::after {
  border-radius: 50%;
}

.style-switcher .style-switcher-toggler {
  top: 0;
}

.style-switcher .day-night {
  top: 55px;
}

.style-switcher h4 {
  margin: 0 0 10px;
  color: var(--text-black-700);
  font-style: 16px;
  font-weight: 600;
  text-transform: capitalize;
}

.style-switcher .colors {
  display: flex;
  flex-wrap: wrap;
  justify-content:space-between;
}

.style-switcher .colors span {
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 50px;
  cursor: pointer;
}

.style-switcher .color-1 {
  background-color: #fb839e;
}
.style-switcher .color-2 {
  background-color: #ec9412;
}
.style-switcher .color-3 {
  background-color: #1fc586;
}
.style-switcher .color-4 {
  background-color: #2eb1ed;
}
.style-switcher .color-5 {
  background-color: #cc3a3b;
}