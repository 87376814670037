.fade-out-effect {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: var(--bg-black-50);
  visibility: hidden;
}

.fade-out-effect.active {
  visibility: visible;
  z-index: 1000;
  animation: fadeOut 0.3s ease-out;
}

.effect-1 {
  width: 30px;
  height: 30px;
  border: 4px solid #8a49ff;
  right: 10%;
  bottom: 10%;
  animation: spin 10s linear infinite;
}
.effect-2 {
  left: 5%;
  bottom: 20%;
  width: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  animation: topBounce 3s ease-in-out infinite;
}
.effect-2 div {
  height: 3px;
  width: 3px;
  background-color: #ff9c07;
  margin: 0 3px 8px;
}
.effect-3 {
  width: 180px;
  height: 180px;
  border: 25px solid var(--skin-color);
  border-radius: 50%;
  left: 50%;
  top: -110px;
  animation: leftBounce 3s ease-in-out infinite;
}
.effect-4 {
  border-top: 30px solid transparent;
  border-left: 30px solid #06d79c;
  left: 30%;
  top: 30%;
  animation: spin 15s linear infinite;
}
.effect-4:before {
  content: "";
  border-top: 30px solid transparent;
  border-left: 30px solid #06d79c;
  position: absolute;
  left: -35px;
  top: -25px;
  opacity: 0.5;
}
.effect-5 {
  width: 50px;
  height: 50px;
  right: 15%;
  top: 30%;
  display: flex;
  justify-content: space-between;
  border-radius: 50%;
  overflow: hidden;
  animation: spin 10s linear infinite;
}
.effect-5 div {
  width: 1px;
  background-color: #4dd0e1;
}

/*-----------
  animations 
------------*/
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes topBounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(25px);
  }
}
@keyframes leftBounce {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(25px);
  }
}
@keyframes fadeInTop {
  0% {
    opacity: 0;
    transform: translateY(-25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*-------------
  home section
 ------------ */
.home-section {
  position: relative;
}
.home-section .full-screen {
  padding: 50px 0;
  min-height: 100vh;
}
.home-section .home-text,
.home-section .home-img {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 20px;
}
.home-section .home-text p {
  color: var(--text-black-300);
  font-size: 18px;
  text-transform: capitalize;
  margin: 0;
}
.home-section .home-text h2 {
  color: var(--text-black-900);
  font-size: 55px;
  font-weight: 700;
  margin: 0;
}
.home-section .home-text h1 {
  color: var(--text-black-600);
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}
.home-section .home-text .btn-1 {
  margin-top: 30px;
}
.home-section .home-img .img-box {
  max-width: 400px;
  display: block;
  margin: auto;
  max-height: 400px;
  border-radius: 50%;
}
.home-section .home-img .img-box img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 50%;
  object-position:top;
  border: 10px solid transparent;
}

@media (max-width: 767px) {
  .about-section .about-img,
  .home-section .home-text, 
  .home-section .home-img {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .home-section .home-text {
    order: 2;
    text-align: center;
  }

  .home-section .full-screen {
    padding: 100px 0;
  }

  .home-section .home-img .img-box {
    margin-bottom: 40px;
    max-width: 350px;
  }

  .effect-3 {
    margin-left: -60px;
  }

  .effect-4 {
    left: 15%;
  }

  .effect-5 {
    right: 4%;
  }
}

@media (max-width: 574px) {
  .home-section .home-img .img-box {
    max-width: 260px;
  }

  
  .home-section .home-text h2 {
    font-size: 40px;
  }
}