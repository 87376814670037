.contact-section {
  padding: 80px 0 80px;
  min-height: 100vh;
}

.contact-section .contact-item {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 15px;
  display: flex;
}

.contact-section .contact-item-inner {
  padding: 30px 15px;
  text-align: center;
  width:100%;
  border-radius: 5px;
}

.contact-section .contact-item-inner i {
  font-size: 25px;
  color: var(--skin-color);
}

.contact-section .contact-item-inner span {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-black-700);
  margin: 15px 0 10px;
  text-transform: capitalize;
}

.contact-section .contact-item-inner p {
  font-size: 16px;
  color: var(--text-black-600);
  line-height: 26px;
  margin: 0;
  word-break: break-word;
}

.contact-section .contact-form {
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 50px;
}

.contact-section .w-50 {
  padding: 0 15px;
  flex: 0 0 50%;
  max-width: 50%;
}

.contact-section .input-group {
  margin: 0 0 25px;
  transition: all 0.3s ease;
  border-radius: 30px;
} 

.contact-section .input-group::after {
  border-radius: 30px;
}

.contact-section .input-group .input-control {
  height: 45px;
  width: 100%;
  display: block;
  border-radius: 30px;
  border: none;
  background-color: transparent;
  font-size: 16px;
  padding: 0 15px;
  color: var(--text-black-700);
}

.contact-section .input-group textarea.input-control {
  height: 184px;
  padding: 15px;
  resize: none;
}

.contact-section .submit-btn {
  flex: 0 0 100%;
  max-width: 100%;
  text-align: right;
  padding-top: 0 15px;
}

@media (max-width: 767px) {
  .contact-section .contact-item,
  .contact-section .w-50 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .contact-section .submit-btn {
    text-align: center;
  }
}