@import url(https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --bg-black-900: #000000;
  --bg-black-100: #dddddd;
  --bg-black-50: #eff0f4;
  --text-black-900: #000000;
  --text-black-700: #555555;
  --text-black-600: #666666;
  --text-black-300: #bbbbbb;
  --outer-shadow: 3px 3px 3px #d0d0d0, -3px -3px 3px #f8f8f8;
  --outer-shadow-0: 0 0 0 #d0d0d0, 0 0 0 #f8f8f8;
  --inner-shadow: inset 3px 3px 3px #d0d0d0, inset -3px -3px 3px #f8f8f8;
  --inner-shadow-0: inset 0 0 0 #d0d0d0, inset 0 0 0 #f8f8f8;
}



@media (max-width: 767px) {
  .home-section .home-text, 
  .home-section .home-img {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.style-switcher {
  position: fixed;
  right: 0;
  top: 77px;
  padding: 15px;
  width: 200px;
  z-index: 101;
  border-radius: 5px;
  transition: all 0.3s ease;
  transform: translateX(100%);
}

.style-switcher.open {
  transform: translateX(-15px);
}

.style-switcher .s-icon {
  position: absolute;
  height: 40px;
  width: 40px;
  text-align: center;
  font-size: 20px;
  color: var(--text-black-900);
  right: 100%;
  margin-right: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 50%;
}

.style-switcher .s-icon i {
  line-height: 40px;
}

.style-switcher .s-icon::after {
  border-radius: 50%;
}

.style-switcher .style-switcher-toggler {
  top: 0;
}

.style-switcher .day-night {
  top: 55px;
}

.style-switcher h4 {
  margin: 0 0 10px;
  color: var(--text-black-700);
  font-style: 16px;
  font-weight: 600;
  text-transform: capitalize;
}

.style-switcher .colors {
  display: flex;
  flex-wrap: wrap;
  justify-content:space-between;
}

.style-switcher .colors span {
  display: inline-block;
  height: 30px;
  width: 30px;
  border-radius: 50px;
  cursor: pointer;
}

.style-switcher .color-1 {
  background-color: #fb839e;
}
.style-switcher .color-2 {
  background-color: #ec9412;
}
.style-switcher .color-3 {
  background-color: #1fc586;
}
.style-switcher .color-4 {
  background-color: #2eb1ed;
}
.style-switcher .color-5 {
  background-color: #cc3a3b;
}
body {
  font-size: 16px;
  line-height: 1.5;
  overflow-x: hidden;
  background-color: var(--bg-black-50);
}

body.dark {
  --bg-black-900: #fff;
  --bg-black-100: #353535;
  --bg-black-50: #2b2c2f;
  --bg-opacity: rgba(43, 44, 47, 0.5);
  --text-black-900: #fff;
  --text-black-700: #fff;
  --text-black-600: #bbb;
  --outer-shadow: 3px 3px 3px #222327, -3px -3px 3px #363636;
  --outer-shadow-0: 0 0 0 #222327, 0 0 0 #363636;
  --inner-shadow: inset 3px 3px 3px #222327, inset -3px -3px 3px #363636;
  --inner-shadow-0: inset 0 0 0 #222327, inset 0 0 0 #363636;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}
*:not(i){
  font-family: 'Raleway', sans-serif;
  font-size: 400;
}
:before,
:after {
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
img {
  vertical-align: middle;
  max-width: 100%;
}
.container {
  max-width: 1140px;
  margin: auto;
}

.section.hide {
  display: none;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
.justify-content-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}
.outer-shadow {
  box-shadow: var(--outer-shadow);
}
.inner-shadow {
  box-shadow: var(--inner-shadow);
}
.hover-in-shadow {
  position: relative;
  z-index: 1;
}
.hover-in-shadow:hover {
  box-shadow: var(--outer-shadow-0);
}
.hover-in-shadow:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  z-index: -1;
}
.hover-in-shadow:hover:after {
  box-shadow: var(--inner-shadow);
}
.btn-1 {
  padding: 10px 25px;
  font-size: 16px;
  font-weight: 500;
  color: var(--skin-color);
  background-color: transparent;
  line-height: 1.5;
  cursor: pointer;
  border-radius: 30px;
  transition: all 0.3s ease;
  display: inline-block;
  border: none;
}
.btn-1:after {
  border-radius: 30px;
}
.effect-wrap .effect {
  position: absolute;
  z-index: -1;
}

/*--------- 
Preloader 
----------*/

.preloader {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1500;
  background-color: var(--bg-black-50);
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloader.fade-out {
  opacity: 0;
  transition: all 0.6s ease;
}

.preloader .box {
  width: 65px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.preloader .box div {
  height: 15px;
  width: 15px;
  background-color: var(--skin-color);
  border-radius: 50%;
  animation: loaderAni 1s ease infinite;
}

.preloader .box div:nth-child(2) {
  animation-delay: 0.1s;
}

.preloader .box div:nth-child(3) {
  animation-delay: 0.2s;
}

@keyframes loaderAni {
  0%,100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
}
.fade-out-effect {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: var(--bg-black-50);
  visibility: hidden;
}

.fade-out-effect.active {
  visibility: visible;
  z-index: 1000;
  animation: fadeOut 0.3s ease-out;
}

.effect-1 {
  width: 30px;
  height: 30px;
  border: 4px solid #8a49ff;
  right: 10%;
  bottom: 10%;
  animation: spin 10s linear infinite;
}
.effect-2 {
  left: 5%;
  bottom: 20%;
  width: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  animation: topBounce 3s ease-in-out infinite;
}
.effect-2 div {
  height: 3px;
  width: 3px;
  background-color: #ff9c07;
  margin: 0 3px 8px;
}
.effect-3 {
  width: 180px;
  height: 180px;
  border: 25px solid var(--skin-color);
  border-radius: 50%;
  left: 50%;
  top: -110px;
  animation: leftBounce 3s ease-in-out infinite;
}
.effect-4 {
  border-top: 30px solid transparent;
  border-left: 30px solid #06d79c;
  left: 30%;
  top: 30%;
  animation: spin 15s linear infinite;
}
.effect-4:before {
  content: "";
  border-top: 30px solid transparent;
  border-left: 30px solid #06d79c;
  position: absolute;
  left: -35px;
  top: -25px;
  opacity: 0.5;
}
.effect-5 {
  width: 50px;
  height: 50px;
  right: 15%;
  top: 30%;
  display: flex;
  justify-content: space-between;
  border-radius: 50%;
  overflow: hidden;
  animation: spin 10s linear infinite;
}
.effect-5 div {
  width: 1px;
  background-color: #4dd0e1;
}

/*-----------
  animations 
------------*/
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes topBounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(25px);
  }
}
@keyframes leftBounce {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(25px);
  }
}
@keyframes fadeInTop {
  0% {
    opacity: 0;
    transform: translateY(-25px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/*-------------
  home section
 ------------ */
.home-section {
  position: relative;
}
.home-section .full-screen {
  padding: 50px 0;
  min-height: 100vh;
}
.home-section .home-text,
.home-section .home-img {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 20px;
}
.home-section .home-text p {
  color: var(--text-black-300);
  font-size: 18px;
  text-transform: capitalize;
  margin: 0;
}
.home-section .home-text h2 {
  color: var(--text-black-900);
  font-size: 55px;
  font-weight: 700;
  margin: 0;
}
.home-section .home-text h1 {
  color: var(--text-black-600);
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}
.home-section .home-text .btn-1 {
  margin-top: 30px;
}
.home-section .home-img .img-box {
  max-width: 400px;
  display: block;
  margin: auto;
  max-height: 400px;
  border-radius: 50%;
}
.home-section .home-img .img-box img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 50%;
  object-position:top;
  border: 10px solid transparent;
}

@media (max-width: 767px) {
  .about-section .about-img,
  .home-section .home-text, 
  .home-section .home-img {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .home-section .home-text {
    order: 2;
    text-align: center;
  }

  .home-section .full-screen {
    padding: 100px 0;
  }

  .home-section .home-img .img-box {
    margin-bottom: 40px;
    max-width: 350px;
  }

  .effect-3 {
    margin-left: -60px;
  }

  .effect-4 {
    left: 15%;
  }

  .effect-5 {
    right: 4%;
  }
}

@media (max-width: 574px) {
  .home-section .home-img .img-box {
    max-width: 260px;
  }

  
  .home-section .home-text h2 {
    font-size: 40px;
  }
}
/*-------- 
  header 
----------*/
.header {
  padding: 20px 15px;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
}
.header .logo a {
  display: inline-block;
  text-align: center;
  line-height: 36px;
  height: 40px;
  width: 40px;
  font-size: 24px;
  font-weight: 600;
  border-radius: 50%;
  color: var(--skin-color);
  border: 2px solid var(--skin-color);
  text-transform: uppercase;
}
.header .hamburger-btn {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s ease;
}
.header .hamburger-btn:after {
  border-radius: 50%;
}
.header .hamburger-btn span {
  display: block;
  height: 2px;
  position: relative;
  width: 16px;
  background-color: var(--bg-black-900);
}
.header .hamburger-btn span:before,
.header .hamburger-btn span:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-black-900);
}
.header .hamburger-btn span:before {
  top: -6px;
}
.header .hamburger-btn span:after {
  top: 6px;
}

/*--------
  nav menu
 =------- */
.nav-menu {
  position: fixed;
  background-color: var(--bg-black-50);
  padding: 0 15px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
}

.nav-menu.open {
  visibility: visible;
  opacity: 1;
  z-index: 999;
  transition: all 0.3s ease-in;
}

.nav-menu .close-nav-menu {
  height: 40px;
  width: 40px;
  display: block;
  font-size: 35px;
  line-height: 42px;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  text-align: center;
  color: var(--text-black-600);
  transition: all 0.3s ease;
}
.nav-menu .close-nav-menu:after {
  border-radius: 50%;
}
.nav-menu-inner {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-menu ul {
  padding: 15px;
}
.nav-menu ul li {
  display: block;
  margin-bottom: 20px;
  text-align: center;
}
.nav-menu ul li:last-child {
  margin-bottom: 0px;
}
.nav-menu ul li a {
  display: inline-block;
  font-size: 28px;
  font-weight: 600;
  color: var(--text-black-600);
  padding: 5px 30px;
  text-transform: capitalize;
  border-radius: 30px;
  transition: all 0.3s ease;
}
.nav-menu ul li a:after {
  border-radius: 30px;
}
.nav-menu ul li a.active {
  color: var(--skin-color);
}
.nav-menu .copyright-text {
  position: absolute;
  top: 50%;
  left: -20px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: var(--text-black-600);
  letter-spacing: 1px;
  transform: translateY(-50%) rotate(-90deg);
}
.section-title {
  padding: 0 15px;
  flex: 0 0 100%;
  max-width: 100%;
  text-align: center;
  margin-bottom: 60px;
}
.section-title h2 {
  display: inline-block;
  font-size: 30px;
  font-weight: 700;
  color: var(--text-black-900);
  text-transform: uppercase;
  margin: 0;
}
.section-title h2:before {
  content: attr(data-heading);
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: var(--skin-color);
}

/*------------
  about section 
------------*/
.about-section {
  padding: 80px 0 0;
  min-height: 100vh;
}
.about-section .about-img {
  flex: 0 0 40%;
  max-width: 40%;
  padding: 0 20px;
}
.about-section .about-img .img-box {
  padding: 15px;
  border-radius: 5px;
}
.about-section .about-img .img-box img {
  width: 100%;
  border: 10px solid transparent;
  height: 400px;
  object-fit: cover;
  object-position: top;
  border-radius: 5px;
}
.about-section .about-img .social-links {
  margin-top: 20px;
  text-align: center;
}
.about-section .about-img .social-links a {
  display: inline-block;
  height: 40px;
  width: 40px;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  color: var(--text-black-600);
  margin: 0 6px;
  border-radius: 50%;
  transition: all 0.3s ease;
}
.about-section .about-img .social-links a:after {
  border-radius: 50%;
}
.about-section .about-img .social-links a:hover {
  color: var(--skin-color);
}
.about-section .about-img .social-links a i {
  line-height: 40px;
}
.about-section .about-info {
  flex: 0 0 60%;
  max-width: 60%;
  padding: 0 20px;
}
.about-section .about-info p {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 15px;
  color: var(--text-black-600);
}
.about-section .about-info span {
  font-weight: 600;
}
.about-section .about-info .btn-1 {
  margin: 30px 20px 0 0;
}
.about-section .about-tabs {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 60px 15px 50px;
  text-align: center;
}
.about-section .about-tabs .tab-item {
  display: inline-block;
  margin: 0 5px 10px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 30px;
  transition: all 0.3s ease;
  font-size: 16px;
  font-weight: 500;
  color: var(--text-black-600);
  text-transform: capitalize;
}
.about-section .about-tabs .tab-item:hover:not(.active) {
  opacity: 0.6;
}
.about-section .about-tabs .tab-item.active {
  color: var(--skin-color);
}
.about-section .tab-content {
  flex: 0 0 100%;
  max-width: 100%;
  display: none;
}
.about-section .tab-content.active {
  display: block;
  animation: fadeInTop 0.5s ease-in-out;
}

@media (max-width: 767px) {
  .about-section .about-img,
  .about-section .about-info {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .about-section .about-img {
    padding-bottom: 30px;
  }
}
/*------------
  about skills
------------*/
.about-section .skills {
  padding: 0 0 40px;
}
.about-section .skills .skill-item {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 15px;
  margin-bottom: 40px;
}
.about-section .skills .skill-item p {
  font-size: 16px;
  color: var(--text-black-600);
  text-transform: capitalize;
  margin: 0 0 10px;
}
.about-section .skills .skill-item .progress {
  height: 20px;
  border-radius: 10px;
  position: relative;
}
.about-section .skills .skill-item .progress-bar {
  background-color: var(--text-black-600);
  height: 6px;
  left: 7px;
  top: 7px;
  position: absolute;
  border-radius: 10px;
}
.about-section .skills .skill-item .progress-bar span {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 16px;
  font-weight: 500;
  color: var(--text-black-600);
  margin-top: -40px;
}

@media (max-width: 767px) {
  .about-section .skills .skill-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
/*------------
  about experience
------------*/
.about-section .education,
.about-section .experience {
  padding-bottom: 80px;
}
.about-section .timeline {
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
}
.about-section .timeline:before {
  content: "";
  width: 1px;
  height: 100%;
  left: 50%;
  top: 0;
  background-color: var(--bg-black-100);
  position: absolute;
}
.about-section .timeline .timeline-item {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 15px;
}
.about-section .timeline .timeline-item:nth-child(odd) {
  padding-right: calc(50% + 50px);
  text-align: right;
}
.about-section .timeline .timeline-item:nth-child(even) {
  padding-left: calc(50% + 50px);
}
.about-section .timeline .timeline-item-inner {
  padding: 30px;
  border-radius: 5px;
  position: relative;
}
.about-section .timeline .timeline-item-inner:before {
  content: "";
  position: absolute;
  width: 30px;
  height: 1px;
  top: 37px;
  background-color: var(--bg-black-100);
  z-index: -1;
}
.about-section
  .timeline
  .timeline-item:nth-child(odd)
  .timeline-item-inner:before {
  right: -30px;
}
.about-section
  .timeline
  .timeline-item:nth-child(even)
  .timeline-item-inner:before {
  left: -30px;
}
.about-section .timeline .timeline-item-inner .icon {
  height: 40px;
  width: 40px;
  background-color: var(--bg-black-50);
  border: 1px solid var(--bg-black-100);
  border-radius: 50%;
  text-align: center;
  font-size: 16px;
  line-height: 40px;
  color: var(--skin-color);
  position: absolute;
  top: 18px;
}
.about-section .timeline .timeline-item:nth-child(odd) .icon {
  right: -70px;
}
.about-section .timeline .timeline-item:nth-child(even) .icon {
  left: -70px;
}
.about-section .timeline .timeline-item-inner span {
  font-weight: 500;
  display: block;
  color: var(--skin-color);
  margin: 0 0 10px;
  text-transform: capitalize;
}
.about-section .timeline .timeline-item-inner h3 {
  font-weight: 600;
  font-size: 20px;
  color: var(--text-black-700);
  margin: 0 0 5px;
  text-transform: capitalize;
}
.about-section .timeline .timeline-item-inner h4 {
  font-style: italic;
  font-size: 16px;
  color: var(--text-black-600);
  margin: 0;
  text-transform: capitalize;
}
.about-section .timeline .timeline-item-inner p {
  font-weight: 600;
  font-size: 16px;
  color: var(--text-black-600);
  line-height: 26px;
  margin: 15px 0 0;
}

@media (max-width: 767px) {
  .about-section .timeline .timeline-item:nth-child(odd) {
    padding-right: 15px;
    text-align: left;
    padding-left: 75px;
  }

  .about-section .timeline .timeline-item:nth-child(even) {
    padding-left: 75px;
  }

  .about-section .timeline:before {
    left: 35px;
  }

  .about-section .timeline-item:nth-child(odd) .icon {
    right: auto;
    left: -60px;
  }

  .about-section .timeline-item:nth-child(even) .icon {
    left: -60px;
  }

  .about-section .timeline .timeline-item {
    margin-bottom: 40px;
  }

  .about-section .timeline .timeline-item:last-child {
    margin-bottom: 0;
  }

  .about-section .timeline .timeline-item:nth-child(odd) .timeline-item-inner::before {
    left: -30px;
    right: auto;
  }
}
.contact-section {
  padding: 80px 0 80px;
  min-height: 100vh;
}

.contact-section .contact-item {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 15px;
  display: flex;
}

.contact-section .contact-item-inner {
  padding: 30px 15px;
  text-align: center;
  width:100%;
  border-radius: 5px;
}

.contact-section .contact-item-inner i {
  font-size: 25px;
  color: var(--skin-color);
}

.contact-section .contact-item-inner span {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: var(--text-black-700);
  margin: 15px 0 10px;
  text-transform: capitalize;
}

.contact-section .contact-item-inner p {
  font-size: 16px;
  color: var(--text-black-600);
  line-height: 26px;
  margin: 0;
  word-break: break-word;
}

.contact-section .contact-form {
  flex: 0 0 100%;
  max-width: 100%;
  margin-top: 50px;
}

.contact-section .w-50 {
  padding: 0 15px;
  flex: 0 0 50%;
  max-width: 50%;
}

.contact-section .input-group {
  margin: 0 0 25px;
  transition: all 0.3s ease;
  border-radius: 30px;
} 

.contact-section .input-group::after {
  border-radius: 30px;
}

.contact-section .input-group .input-control {
  height: 45px;
  width: 100%;
  display: block;
  border-radius: 30px;
  border: none;
  background-color: transparent;
  font-size: 16px;
  padding: 0 15px;
  color: var(--text-black-700);
}

.contact-section .input-group textarea.input-control {
  height: 184px;
  padding: 15px;
  resize: none;
}

.contact-section .submit-btn {
  flex: 0 0 100%;
  max-width: 100%;
  text-align: right;
  padding-top: 0 15px;
}

@media (max-width: 767px) {
  .contact-section .contact-item,
  .contact-section .w-50 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .contact-section .submit-btn {
    text-align: center;
  }
}
.portfolio-section {
  padding: 80px 0 80px;
  min-height: 100vh;
}
.portfolio-section .section-title {
  margin-bottom: 40px;
}
.portfolio-section .portfolio-filter {
  padding: 0 15px;
  flex: 0 0 100%;
  max-width: 100%;
  text-align: center;
  margin-bottom: 20px;
}
.portfolio-section .filter-item {
  display: inline-block;
  margin: 0 5px 10px;
  padding: 5px 10px;
  border-radius: 30px;
  cursor: pointer;
  font-weight: 500;
  color: var(--text-black-600);
  text-transform: capitalize;
  font-size: 16px;
  transition: all 0.3s ease;
}
.portfolio-section .filter-item.active {
  color: var(--skin-color);
}
.portfolio-section .filter-item:hover:not(.active) {
  opacity: 0.6;
}
.portfolio-section .portfolio-item {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
}
.portfolio-section .portfolio-item.hide {
  display: none;
}
.portfolio-section .portfolio-item.show {
  display: block;
  animation: fadeInTop 0.5s ease;
}
.portfolio-section .portfolio-item-inner {
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}
.portfolio-section .portfolio-item-inner p {
  font-size: 16px;
  margin: 10px 0 0;
  color: var(--text-black-600);
  text-transform: capitalize;
}
.portfolio-section .portfolio-item-img img {
  border-radius: 5px;
  width: 100%;
  transition: all 0.3s ease;
}
.portfolio-section .portfolio-item-inner:hover .portfolio-item-img img {
  transform: translateY(-25px);
}
.portfolio-section .portfolio-item-img {
  position: relative;
}
.portfolio-section .portfolio-item-img .view-project {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  color: var(--skin-color);
  transition: all 0.3s ease;
  position: absolute;
  left: 0;
  bottom: -4px;
  transform: translateY(-5px);
  z-index: -1;
  opacity: 0;
}
.portfolio-section .portfolio-item-inner:hover .view-project {
  transform: translateY(0px);
  opacity: 1;
}
.portfolio-section .portfolio-item-details {
  display: none;
}


@media (max-width: 991px) {
  .portfolio-section .portfolio-item {
    flex: 0 0 50%;
    max-width: 50%;
  }

}

@media (max-width: 767px) {
  .portfolio-section .portfolio-item {
    flex: 0 0 100%;
    max-width: 100%;
  }

}
