/*------------
  about skills
------------*/
.about-section .skills {
  padding: 0 0 40px;
}
.about-section .skills .skill-item {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 15px;
  margin-bottom: 40px;
}
.about-section .skills .skill-item p {
  font-size: 16px;
  color: var(--text-black-600);
  text-transform: capitalize;
  margin: 0 0 10px;
}
.about-section .skills .skill-item .progress {
  height: 20px;
  border-radius: 10px;
  position: relative;
}
.about-section .skills .skill-item .progress-bar {
  background-color: var(--text-black-600);
  height: 6px;
  left: 7px;
  top: 7px;
  position: absolute;
  border-radius: 10px;
}
.about-section .skills .skill-item .progress-bar span {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 16px;
  font-weight: 500;
  color: var(--text-black-600);
  margin-top: -40px;
}

@media (max-width: 767px) {
  .about-section .skills .skill-item {
    flex: 0 0 100%;
    max-width: 100%;
  }
}