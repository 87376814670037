/*-------- 
  header 
----------*/
.header {
  padding: 20px 15px;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
}
.header .logo a {
  display: inline-block;
  text-align: center;
  line-height: 36px;
  height: 40px;
  width: 40px;
  font-size: 24px;
  font-weight: 600;
  border-radius: 50%;
  color: var(--skin-color);
  border: 2px solid var(--skin-color);
  text-transform: uppercase;
}
.header .hamburger-btn {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s ease;
}
.header .hamburger-btn:after {
  border-radius: 50%;
}
.header .hamburger-btn span {
  display: block;
  height: 2px;
  position: relative;
  width: 16px;
  background-color: var(--bg-black-900);
}
.header .hamburger-btn span:before,
.header .hamburger-btn span:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-black-900);
}
.header .hamburger-btn span:before {
  top: -6px;
}
.header .hamburger-btn span:after {
  top: 6px;
}

/*--------
  nav menu
 =------- */
.nav-menu {
  position: fixed;
  background-color: var(--bg-black-50);
  padding: 0 15px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
}

.nav-menu.open {
  visibility: visible;
  opacity: 1;
  z-index: 999;
  transition: all 0.3s ease-in;
}

.nav-menu .close-nav-menu {
  height: 40px;
  width: 40px;
  display: block;
  font-size: 35px;
  line-height: 42px;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  text-align: center;
  color: var(--text-black-600);
  transition: all 0.3s ease;
}
.nav-menu .close-nav-menu:after {
  border-radius: 50%;
}
.nav-menu-inner {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-menu ul {
  padding: 15px;
}
.nav-menu ul li {
  display: block;
  margin-bottom: 20px;
  text-align: center;
}
.nav-menu ul li:last-child {
  margin-bottom: 0px;
}
.nav-menu ul li a {
  display: inline-block;
  font-size: 28px;
  font-weight: 600;
  color: var(--text-black-600);
  padding: 5px 30px;
  text-transform: capitalize;
  border-radius: 30px;
  transition: all 0.3s ease;
}
.nav-menu ul li a:after {
  border-radius: 30px;
}
.nav-menu ul li a.active {
  color: var(--skin-color);
}
.nav-menu .copyright-text {
  position: absolute;
  top: 50%;
  left: -20px;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  color: var(--text-black-600);
  letter-spacing: 1px;
  transform: translateY(-50%) rotate(-90deg);
}