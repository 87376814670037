.section-title {
  padding: 0 15px;
  flex: 0 0 100%;
  max-width: 100%;
  text-align: center;
  margin-bottom: 60px;
}
.section-title h2 {
  display: inline-block;
  font-size: 30px;
  font-weight: 700;
  color: var(--text-black-900);
  text-transform: uppercase;
  margin: 0;
}
.section-title h2:before {
  content: attr(data-heading);
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: var(--skin-color);
}

/*------------
  about section 
------------*/
.about-section {
  padding: 80px 0 0;
  min-height: 100vh;
}
.about-section .about-img {
  flex: 0 0 40%;
  max-width: 40%;
  padding: 0 20px;
}
.about-section .about-img .img-box {
  padding: 15px;
  border-radius: 5px;
}
.about-section .about-img .img-box img {
  width: 100%;
  border: 10px solid transparent;
  height: 400px;
  object-fit: cover;
  object-position: top;
  border-radius: 5px;
}
.about-section .about-img .social-links {
  margin-top: 20px;
  text-align: center;
}
.about-section .about-img .social-links a {
  display: inline-block;
  height: 40px;
  width: 40px;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  color: var(--text-black-600);
  margin: 0 6px;
  border-radius: 50%;
  transition: all 0.3s ease;
}
.about-section .about-img .social-links a:after {
  border-radius: 50%;
}
.about-section .about-img .social-links a:hover {
  color: var(--skin-color);
}
.about-section .about-img .social-links a i {
  line-height: 40px;
}
.about-section .about-info {
  flex: 0 0 60%;
  max-width: 60%;
  padding: 0 20px;
}
.about-section .about-info p {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 15px;
  color: var(--text-black-600);
}
.about-section .about-info span {
  font-weight: 600;
}
.about-section .about-info .btn-1 {
  margin: 30px 20px 0 0;
}
.about-section .about-tabs {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 60px 15px 50px;
  text-align: center;
}
.about-section .about-tabs .tab-item {
  display: inline-block;
  margin: 0 5px 10px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 30px;
  transition: all 0.3s ease;
  font-size: 16px;
  font-weight: 500;
  color: var(--text-black-600);
  text-transform: capitalize;
}
.about-section .about-tabs .tab-item:hover:not(.active) {
  opacity: 0.6;
}
.about-section .about-tabs .tab-item.active {
  color: var(--skin-color);
}
.about-section .tab-content {
  flex: 0 0 100%;
  max-width: 100%;
  display: none;
}
.about-section .tab-content.active {
  display: block;
  animation: fadeInTop 0.5s ease-in-out;
}

@media (max-width: 767px) {
  .about-section .about-img,
  .about-section .about-info {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .about-section .about-img {
    padding-bottom: 30px;
  }
}