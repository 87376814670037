/*------------
  about experience
------------*/
.about-section .education,
.about-section .experience {
  padding-bottom: 80px;
}
.about-section .timeline {
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
}
.about-section .timeline:before {
  content: "";
  width: 1px;
  height: 100%;
  left: 50%;
  top: 0;
  background-color: var(--bg-black-100);
  position: absolute;
}
.about-section .timeline .timeline-item {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 15px;
}
.about-section .timeline .timeline-item:nth-child(odd) {
  padding-right: calc(50% + 50px);
  text-align: right;
}
.about-section .timeline .timeline-item:nth-child(even) {
  padding-left: calc(50% + 50px);
}
.about-section .timeline .timeline-item-inner {
  padding: 30px;
  border-radius: 5px;
  position: relative;
}
.about-section .timeline .timeline-item-inner:before {
  content: "";
  position: absolute;
  width: 30px;
  height: 1px;
  top: 37px;
  background-color: var(--bg-black-100);
  z-index: -1;
}
.about-section
  .timeline
  .timeline-item:nth-child(odd)
  .timeline-item-inner:before {
  right: -30px;
}
.about-section
  .timeline
  .timeline-item:nth-child(even)
  .timeline-item-inner:before {
  left: -30px;
}
.about-section .timeline .timeline-item-inner .icon {
  height: 40px;
  width: 40px;
  background-color: var(--bg-black-50);
  border: 1px solid var(--bg-black-100);
  border-radius: 50%;
  text-align: center;
  font-size: 16px;
  line-height: 40px;
  color: var(--skin-color);
  position: absolute;
  top: 18px;
}
.about-section .timeline .timeline-item:nth-child(odd) .icon {
  right: -70px;
}
.about-section .timeline .timeline-item:nth-child(even) .icon {
  left: -70px;
}
.about-section .timeline .timeline-item-inner span {
  font-weight: 500;
  display: block;
  color: var(--skin-color);
  margin: 0 0 10px;
  text-transform: capitalize;
}
.about-section .timeline .timeline-item-inner h3 {
  font-weight: 600;
  font-size: 20px;
  color: var(--text-black-700);
  margin: 0 0 5px;
  text-transform: capitalize;
}
.about-section .timeline .timeline-item-inner h4 {
  font-style: italic;
  font-size: 16px;
  color: var(--text-black-600);
  margin: 0;
  text-transform: capitalize;
}
.about-section .timeline .timeline-item-inner p {
  font-weight: 600;
  font-size: 16px;
  color: var(--text-black-600);
  line-height: 26px;
  margin: 15px 0 0;
}

@media (max-width: 767px) {
  .about-section .timeline .timeline-item:nth-child(odd) {
    padding-right: 15px;
    text-align: left;
    padding-left: 75px;
  }

  .about-section .timeline .timeline-item:nth-child(even) {
    padding-left: 75px;
  }

  .about-section .timeline:before {
    left: 35px;
  }

  .about-section .timeline-item:nth-child(odd) .icon {
    right: auto;
    left: -60px;
  }

  .about-section .timeline-item:nth-child(even) .icon {
    left: -60px;
  }

  .about-section .timeline .timeline-item {
    margin-bottom: 40px;
  }

  .about-section .timeline .timeline-item:last-child {
    margin-bottom: 0;
  }

  .about-section .timeline .timeline-item:nth-child(odd) .timeline-item-inner::before {
    left: -30px;
    right: auto;
  }
}