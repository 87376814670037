@import './css/colors.css';
@import "./css//responsive.css";
@import './css/style-switcher.css';
@import '../node_modules/@fortawesome/fontawesome-free/css/all.css';
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');

body {
  font-size: 16px;
  line-height: 1.5;
  overflow-x: hidden;
  background-color: var(--bg-black-50);
}

body.dark {
  --bg-black-900: #fff;
  --bg-black-100: #353535;
  --bg-black-50: #2b2c2f;
  --bg-opacity: rgba(43, 44, 47, 0.5);
  --text-black-900: #fff;
  --text-black-700: #fff;
  --text-black-600: #bbb;
  --outer-shadow: 3px 3px 3px #222327, -3px -3px 3px #363636;
  --outer-shadow-0: 0 0 0 #222327, 0 0 0 #363636;
  --inner-shadow: inset 3px 3px 3px #222327, inset -3px -3px 3px #363636;
  --inner-shadow-0: inset 0 0 0 #222327, inset 0 0 0 #363636;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}
*:not(i){
  font-family: 'Raleway', sans-serif;
  font-size: 400;
}
:before,
:after {
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
img {
  vertical-align: middle;
  max-width: 100%;
}
.container {
  max-width: 1140px;
  margin: auto;
}

.section.hide {
  display: none;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
.justify-content-between {
  justify-content: space-between;
}
.align-items-center {
  align-items: center;
}
.outer-shadow {
  box-shadow: var(--outer-shadow);
}
.inner-shadow {
  box-shadow: var(--inner-shadow);
}
.hover-in-shadow {
  position: relative;
  z-index: 1;
}
.hover-in-shadow:hover {
  box-shadow: var(--outer-shadow-0);
}
.hover-in-shadow:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  z-index: -1;
}
.hover-in-shadow:hover:after {
  box-shadow: var(--inner-shadow);
}
.btn-1 {
  padding: 10px 25px;
  font-size: 16px;
  font-weight: 500;
  color: var(--skin-color);
  background-color: transparent;
  line-height: 1.5;
  cursor: pointer;
  border-radius: 30px;
  transition: all 0.3s ease;
  display: inline-block;
  border: none;
}
.btn-1:after {
  border-radius: 30px;
}
.effect-wrap .effect {
  position: absolute;
  z-index: -1;
}

/*--------- 
Preloader 
----------*/

.preloader {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1500;
  background-color: var(--bg-black-50);
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloader.fade-out {
  opacity: 0;
  transition: all 0.6s ease;
}

.preloader .box {
  width: 65px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.preloader .box div {
  height: 15px;
  width: 15px;
  background-color: var(--skin-color);
  border-radius: 50%;
  animation: loaderAni 1s ease infinite;
}

.preloader .box div:nth-child(2) {
  animation-delay: 0.1s;
}

.preloader .box div:nth-child(3) {
  animation-delay: 0.2s;
}

@keyframes loaderAni {
  0%,100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
}